import { createSlice } from "@reduxjs/toolkit";

export const initialCustomCode = {
  id: "",
  header_code: "",
  footer_code: "",
  custom_css: "",
  created_by: "",
  created_at: "",
}

export const initialTheme = {
  id: "",
  theme_colour: "theme-blue-blueGrey",
  layout: "card",
  font: null,
  toc_global: false,
  translation_strings: null,
  background: "default",
  custom_theme: "",
  partner_url: "",
  branding: true,
  rtl: false,
  createdby: "",
  created_at: "",
}

export const initialNewsletterC = {
  id: "",
  title: "",
  subtitle: "",
  acceptance: "",
  webhook_url: "",
  created_by: "",
  created_at: "",
}

export const initialIntegrations = {
  id: "",
  disqus: "",
  ga4: "",
  gsc: "",
  adsense: "",
  gated_content: "",
  created_by: "",
  created_at: "",
}

export const initialState = {
  id: "",
  username: null,
  posts: 0,
  category: 0,
  created_at: "",
  email: "",
  full_name: "",
  avatar_url: "",
  href: "",
  title: "",
  description: "",
  logoimg: "",
  logoimgdark: "",
  faviconimg: "",
  aurl: "",
  cus_domain: null,
  cus_domain_d: [
    { name: "API Hit", status: false },
    { name: "DNS", status: false },
    { name: "Resolving", status: false },
    { name: "SSL", status: false },
  ],
  prev_name: null,
  metatitle: "",
  newsletter: 0,
  refauthors: 0,
  newsletterC: {},
  darkmode: true,
  metadescription: "",
  custom_code: initialCustomCode,
  users: {
    id: "",
    full_name: "",
    avatar_url: "",
    created_at: "",
    subscription: false,
    lifetime: null,
    saaszilla_coupon_code: null,
    teams: [],
    total_team_members: 0,
    startup: null,
    slug: null,
    description: null,
    socials: null,
  },
  theme: initialTheme,
  newslettermode: true,
  footertxt: "",
  subdirectory: null,
  opengraph_img: null,
  deleted: false,
  logo_url: null,
  home_page: null,
  pages: null,
  cta: null,
  integrations: initialIntegrations,
  tags: 0,
  featured_posts: [],
};

export const authorSlice = createSlice({
  name: "userData/author",
  initialState,
  reducers: {
    setAuthorData: (_, action) => action.payload,
    updateAuthorData: (state, action) => {
      state.title = action.payload.title;
      state.description = action.payload.description;
      state.username = action.payload.username;
      state.logoimg = action.payload.logoimg;
      state.logoimgdark = action.payload.logoimgdark;
      state.faviconimg = action.payload.faviconimg;
      state.aurl = action.payload.aurl;
      state.darkmode = action.payload.darkmode;
      state.footertxt = action.payload.footertxt;
      state.logo_url = action.payload.logo_url;
    },
    updateOwnerData: (state, action) => { 
      state.users.full_name = action.payload.full_name;
      state.users.avatar_url = action.payload.avatar_url;
      state.users.slug = action.payload.slug;
      state.users.description = action.payload.description;
      state.users.socials = action.payload.socials;
    },
    updateNewsLetterData: (state, action) => {
      state.newsletterC = action.payload.newsletterC;
    },
    updateNewsLetterMode: (state, action) => {
      state.newslettermode = action.payload.newslettermode;
    },
    updateSEOData: (state, action) => {
      state.metatitle = action.payload.metatitle;
      state.metadescription = action.payload.metadescription;
    },
    updateadvSEOData: (state, action) => {
      state.opengraph_img = action.payload.opengraph_img;
    },
    updateCustomCode: (state, action) => {
      state.custom_code = action.payload;
    },
    updateIntegrations: (state, action) => {
      state.integrations = action.payload;
    },
    incAuthorPosts: (state) => {
      state.posts++;
    },
    incAuthorPages: (state) => {
      state.pages++;
    },
    decAuthorPosts: (state) => {
      state.posts--;
    },
    decAuthorPages: (state) => {
      state.pages--;
    },
    incAuthorCategory: (state) => {
      state.category++;
    },
    decAuthorCategory: (state) => {
      state.category--;
    },
    incAuthorTags: (state) => {
      state.tags++;
    },
    decAuthorTags: (state) => {
      state.tags--;
    },
    updateAuthorNewsletter: (state, action) => {
      state.newsletter = action.payload.newsletter;
    },
    decAuthorNewsletter: (state) => {
      state.newsletter--;
    },
    updateAuthorRefAuthors: (state, action) => {
      state.refauthors = action.payload;
    },
    updateCusDomain: (state, action) => {
      state.cus_domain = action.payload.cus_domain;
    },
    updateCusDomainSubDirectory: (state, action) => {
      state.subdirectory = action.payload.subdirectory;
    },
    updateCusDomainD: (state, action) => {
      state.cus_domain_d = action.payload.cus_domain_d;
    },
    updateUserCouponCode: (state, action) => {
      state.users.saaszilla_coupon_code = action.payload.saaszilla_coupon_code;
      state.users.lifetime = action.payload.lifetime;
    },
    updateHomePage: (state, action) => {
      state.pages = action.payload.pages;
    },
    updateGlobalCTA: (state, action) => {
      state.cta = action.payload.cta;
    },
    updateOrgData: (state, action) => {
      state.org = action.payload;
    },
    updateOrgName: (state, action) => {
      state.org.name = action.payload.name;
    },
    syncCount: (state, action) => {
      state.posts = action.payload.posts;
      state.category = action.payload.category;
      state.newsletter = action.payload.newsletter;
    },
    updateTheme: (state, action) => {
      state.theme = action.payload;
    },
    updateFeaturedPosts: (state, action) => {
      state.featured_posts = action.payload.featured_posts;
    },
    setEmptyA: () => initialState,
  },
});

export const {
  setAuthorData,
  updateAuthorData,
  updateOwnerData,
  updateNewsLetterData,
  updateNewsLetterMode,
  updateSEOData,
  updateadvSEOData,
  updateCustomCode,
  updateIntegrations,
  incAuthorPosts,
  incAuthorPages,
  decAuthorPosts,
  decAuthorPages,
  incAuthorCategory,
  decAuthorCategory,
  incAuthorTags,
  decAuthorTags,
  updateAuthorNewsletter,
  decAuthorNewsletter,
  updateAuthorRefAuthors,
  updateCusDomain,
  updateCusDomainSubDirectory,
  updateCusDomainD,
  updateUserCouponCode,
  updateHomePage,
  updateGlobalCTA,
  updateOrgData,
  updateOrgName,
  syncCount,
  updateTheme,
  updateFeaturedPosts,
  setEmptyA,
} = authorSlice.actions;

export default authorSlice.reducer;
